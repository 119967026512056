import React, { useState, useEffect, useCallback, useRef} from 'react';

const MazeGenerator = ({ heigth, width }) => {
  const [maze, setMaze] = useState(Array.from({ length: heigth }, () =>
  Array.from({ length: width }, () => 0)));
  const [start, setStart] = useState(0);
  const [x,setX] = useState(0)
  const [y,setY] = useState(0)
  
  function getRandomInt(min, max) {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }

  function shuffleArray(array) {
    let newArray = []
    let done = []
    for (let i = 0; i < array.length; i++) {
      let j = getRandomInt(0,array.length-1)
      while(done.includes(j)){
        j = getRandomInt(0,array.length-1)
        done.push(j)
      }
      newArray.push(array[j])
    }
    
    return newArray
  }

  function isPossiblePath(maze,x,y,originX,originY){
    let directionX = x-originX
    let directionY = y-originY
    let w = [[originX]]
    let directions = [[1,0],[0,1],[-1,0],[0,-1],[1,1],[-1,-1],[-1,1],[1,-1]]
    let paths = 0
    let diags = 0


    /*let xf = x-1
    let yf = y-1
    let blacks = 0
    for(let i = 0; i<3; i++){
      for(let j = 0; j<3; j++){
        if(yf-1+i < maze.length-1 && xf-1+j < maze[0].length-1 && yf-1+i > 0 && xf-1+j > 0){
          if(maze[yf-1+i][xf-1+j] === 1){
            blacks++
          }
        }
      }
    }

    console.log(blacks)
    if(blacks > 0){
      return 0
    }
    return 1*/

    for(let i = 0; i<directions.length; i++){
      if(y < maze.length-1 && x < maze[0].length-1 && y > 0 && x > 0){

        let xForward = x+directions[i][0];
        let yForward = y+directions[i][1];
        //console.log("x: "+x+" y: "+y+" xForward: " + xForward + " yForward: " + yForward+ " Heigth: " + (maze.length-1) + " Width: " + (maze[0].length-1) + " Direction: " + directions[i])
        if(yForward < maze.length-1 && xForward < maze[0].length-1 && yForward > 0 && xForward > 0){
          
          if(maze[yForward][xForward] === 1 && i < 4){
            //console.log("OUi")
            paths+=1
          }else if(maze[yForward][xForward] === 1 && i > 3){
            if(maze[yForward+(directions[i][0]*(-1))][xForward]+maze[yForward][xForward+(directions[i][1]*(-1))] !== 1){
              return 0
            }
            diags += 1
          }
        }
      }
      else{
        return 0
      }
    }


    if(paths <= 1 && diags <= 1 ){
      return 1
    }
    return 0
  }

  function findPath(maze,x,y){
    let directions = [[1,0],[0,1],[-1,0],[0,-1]]
    directions = shuffleArray(directions)
    //console.log(directions)
    let coord = 0

    for(let i = 0; i<directions.length; i++){
      let xForward = x+directions[i][0];
      let yForward = y+directions[i][1];
      
      if(isPossiblePath(maze,xForward,yForward,x,y) !== 0 && maze[yForward][xForward] !== 1){
        coord = [xForward,yForward]
        break
      }
    }

    if (coord!==0){
      return coord
    }
    return 0
  }

  function hasPossiblePaths(maze){
    for(let i = 1; i<maze.length-1;i++){
      for(let j = 1; j<maze[0].length-1; j++){
        if(maze[i][j] === 1){
          let path = findPath(maze,j,i)
          if(path !== 0){
            return path
          }
        }
      }
    }
    return 0
  }

  
  const initMaze = () =>{
    const newMaze = Array.from({ length: heigth }, () =>
          Array.from({ length: width }, () => 0)
    );

    setX(getRandomInt(1,width-1))
    setY(getRandomInt(1,heigth-1))

    newMaze[y][x] = 1

    console.log("start: " + x + "," + y)

    setMaze(newMaze)
  }



  const generateMaze = () => {
      const newMaze = [...maze]

      if(hasPossiblePaths(newMaze) !== 0){
        let path = findPath(newMaze,x,y)
        //console.log(path)
        if(path !== 0){
          //console.log(path)
          setX(path[0])
          setY(path[1])
          newMaze[y][x] = 1
        }
        else if(hasPossiblePaths(newMaze) !== 0){
          //console.log("else")
          path = hasPossiblePaths(newMaze)
          //console.log(path + " else")
          setX(path[0])
          setY(path[1])
          newMaze[y][x] = 1
        }
        setMaze(newMaze)
      }
  }

  const handleClick = () =>{
    if(!start){
      initMaze()
    }
    setStart(!start)
  }



  useEffect(() => {
    const timer = setInterval(() => {
      if(start){
        generateMaze()
      }else{
        initMaze()
        setStart(!start)
      }
    }, 1);
    return () => clearInterval(timer);
  }, [generateMaze]);

  return (
    <div>
      <div className='d-flex column m-auto' style={{width:"fit-content", border:"white solid 3px"}}>
          {maze.map((row, rowIndex) => (
              <div key={rowIndex}>
                  {row.map((cell, colIndex) => (
                      <div className='pixel-2' style={{backgroundColor:`${cell === 0 ? "black":"white"}`}} key={colIndex}></div>
                  ))}
              </div>
          ))}
      </div>
            
      <button className='position-relative start-50 translate-middle-x mt-5 border border-light rounded p-3' style={
          {
            backgroundColor:"transparent", 
            color:"white", 
            fontSize:"20px"
          }
      } onClick={handleClick}>{start ? "New Generation":"Generate"} </button>
    </div>
  );
};

export default MazeGenerator;
