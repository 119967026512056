import React from "react"; 
import 'bootstrap/dist/css/bootstrap.min.css'; 
import { Nav } from 'react-bootstrap';
import { TemporaryDrawer } from './pages/Drawer'


function Navbar() {   


    return (    
        <Nav className="justify-content-between p-3" style={{fontSize:"1.3rem"}} >
            <Nav.Link className="link" href="/">Bensit.space</Nav.Link>
            <div className="d-flex flex-row">
                <Nav.Link className="link display" href="/">Home</Nav.Link>
                <Nav.Link className="link display" href="/#About-me">About</Nav.Link>
                <Nav.Link className="link display" href="/#Projects">Projects</Nav.Link>
                <Nav.Link className="link display" href="/#Contact">Contact</Nav.Link>
                <Nav.Link className="link"><TemporaryDrawer ></TemporaryDrawer></Nav.Link>
            </div>
        </Nav> 
    ); 
} export default Navbar;