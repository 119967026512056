import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter, Routes, Route } 
from "react-router-dom"; 
import Layout from "./pages/Layout";
import Flipflap from './pages/Flipflap'; 
import Home from "./pages/Home"; 
import AramTeam from "./pages/AramTeam"
import NoPage from "./pages/NoPage"
import './index.css';
import reportWebVitals from './reportWebVitals';


/*const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);*/

export default function App() {   
  return (     
    <BrowserRouter>       
      <Routes>         
        <Route path="/" element={<Layout/>}>           
        <Route index element={<Home />} />                    
        <Route path="/flipflap" element={<NoPage/>}/>  
        <Route path="/aramteam" element={<AramTeam/>}/>  
        </Route>     
      </Routes>     
    </BrowserRouter>   
  ); 
} 
const root = createRoot(document.getElementById("root"));
root.render(<App/>);


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();