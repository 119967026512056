import * as React from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import { Nav } from 'react-bootstrap';
import MenuIcon from '@mui/icons-material/Menu';
import IconButton from '@mui/material/IconButton';
import Toolbar from '@mui/material/Toolbar';
import { Divider } from '@mui/material';

export function TemporaryDrawer() {
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const list = (anchor) => (
    <Box className="p-5 h-100"
      sx={{ width: "200px",textAlign:"center", backgroundColor:"rgba(0,0,0,0.7)" }}
      role="presentation"
      
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
        <Nav className="justify-content-center z-3" style={{fontSize:"1.2rem"}} >
            <div className="d-flex flex-column gap-3 ">
                <Nav.Link className="link" href="/">Home</Nav.Link>
                <Nav.Link className="link" href="/#About-me">About</Nav.Link>
                <Nav.Link className="link" href="/#Projects">Projects</Nav.Link>
                <Nav.Link className="link" href="/#Contact">Contact</Nav.Link>
            </div>
        </Nav> 

    </Box>
  );

  return (
    <React.Fragment key={'right'} >
      <Toolbar className='p-0 m-0 hfc no-display'>
        <IconButton
            className='p-0 m-0'
            color="inherit"
            aria-label="open drawer"
            
            onClick={toggleDrawer('right', true)}
            
          >
            <MenuIcon className='p-0 m-0'/>
        </IconButton>
      </Toolbar>
      <Drawer
        
        anchor={'right'}
        open={state['right']}
        onClose={toggleDrawer('right', false)}
      >
        {list('right')}
      </Drawer>
    </React.Fragment>

  );
}
