import { useState } from 'react';
import axios from 'axios';
import '../index.css';
import 'bootstrap/dist/css/bootstrap.min.css'; 
import flipAnimation from '../json/flipflap.json'
import Lottie from 'lottie-react'


function Team({team}){
  return(
    <a href="#" class="list-group-item green darker m-2 rounded border-none" aria-current="true">
      <div class="d-flex w-100 gap-3">
        <img class="img-fluid team_logo" src={team.logo}></img>
        <div class="column">
          <h4 class="mb-1">{team.name}</h4>
          <p class="mb-1">{team.country}</p>
        </div>
        
      </div>
    </a>
  )
}

function Flipflap(){
  const [query,setQuery] = useState('')
  const [teams,setTeams] = useState([])
  
  let options = {
    method: 'GET',
    url: 'https://api-football-v1.p.rapidapi.com/v3/teams',
    params: {search: query},
    headers: {
      'X-RapidAPI-Key': '2f8643319fmshe527a9d5f7485d3p1a2c6djsn9405074c83ca',
      'X-RapidAPI-Host': 'api-football-v1.p.rapidapi.com'
    }
  };

  async function HandleInputChange(event){
    setQuery(event.target.value);
    //const res = await axios.request(options)
    //setTeams(res.data.response)
    setTeams([{team:{
      logo:"flip_flap.png",
      name:"oui",
      country:"France"
    }},{team:{
      logo:"flip_flap.png",
      name:"oui",
      country:"France"
    }}])
  }

  return (
      <div className='list-group'>

        <Lottie className='w-25 position-relative start-50 translate-middle-x' animationData={flipAnimation}></Lottie>

        
        <div className="m-2 input-group w-50 position-relative start-50 translate-middle-x">
          
          <span className="input-group-text border-none darker radius-left">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" className="bi bi-search" fill='#000000' viewBox="0 0 16 16">
              <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0"></path>
            </svg>
          </span>
                
          <input className=" radius-right form-control border-none darker input-team" type="text" placeholder='Search...' value={query} onChange={HandleInputChange}></input>
        </div>
        <section>
          <div className="vstack w-50 position-relative start-50 translate-middle-x">
            {teams.map(team =>
                <Team team={team.team}></Team>
            )}
          </div>
        </section>
      </div>
  );
} export default Flipflap;