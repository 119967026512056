import * as React from 'react';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Avatar from '@mui/material/Avatar';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import {blue,red,yellow,green,purple,orange,cyan,lightBlue,lightGreen,deepOrange,deepPurple} from '@mui/material/colors';


const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});


function getRandomInt(min,max) {
    return Math.floor(min + Math.random() * (max-min));
}

function LetterAvatars({players,handleClick}) {
    return (
      <Stack direction="row" spacing={2} className='d-flex justify-content-around'>
        {players.map(player=>{
            return <Avatar style={{cursor:"pointer"}} title={player[0]} key={player[0]} onClick={handleClick} sx={{bgcolor: player[1], width: "60px",height: "60px"}}>{player[0][0]}{player[0][1]}</Avatar>
        })}
      </Stack>
    );
  }

function BasicSelect({values,label,currentValue,handleChange}) {
  return (
    <Box sx={{ minWidth: 120 }}>
      <FormControl fullWidth>
        <InputLabel id="demo-simple-select-label">{label}</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={currentValue}
          label="Team Size"
          onChange={handleChange}
        >
          {values.map(value =>{
            return <MenuItem key={value} value={value}>{value}</MenuItem>
          })}  
        </Select>
      </FormControl>
    </Box>
  );
}




function AramTeam(){ 
    const [players,setPlayers] = React.useState([])
    const [teams,setTeams] = React.useState([])
    const [name,setName] = React.useState("")
    const [size,setSize] = React.useState(2)
    const [number,setNumber] = React.useState(2)
    const [error,setError] = React.useState("")
    const [open, setOpen] = React.useState(false);


    const handleSnackClose = (event, reason) => {
        if (reason === 'clickaway') {
        return;
        }
        setOpen(false);
    };

    function handleRemovePlayer(event){
        const newPlayers = players.filter((player) => {
            return player[0] !== event.target.title;
        });
        setPlayers(newPlayers)

    }

    function handleNameChange(event){
        setName(event.target.value)
    }

    function handleAddClick(){
        let colors = [blue[500],blue[900],red[500],red[900],yellow[500],yellow[900],green[500],green[900],purple[500],purple[900],orange[500],orange[900],cyan[500],cyan[900]]
        let newP = [name,colors[getRandomInt(0,colors.length)]]
        setPlayers([...players,newP])
    }

    function handleSizeChange(event){
        setSize(event.target.value)
    }

    function handleNumberChange(event){
        setNumber(event.target.value)
    }

    function handleResetClick(){
        setPlayers([])
        setTeams([])
        console.log(players)
    }

    function handlePlayClick(){
        if(players.length < size*number){
            setError("Size or number of team is too high")
            setOpen(true)
            console.log()
            return
        } else if(players.length > size*number){
            setError("Too many players")
            setOpen(true)
            return
        }

        setTeams([])
        let rand = getRandomInt(0,players.length)
        let randoms = []
        let newTeams = []

        for(let j=0; j<number; j++){
            let team = []
            for(let i=0; i<size; i++){
                while(randoms.includes(rand)){
                    rand = getRandomInt(0,players.length)
                }
                randoms.push(rand)
                team.push(players[rand])
            }
            newTeams.push(team)
            team = []
        }

        setTeams(newTeams)

        console.log(teams)
        
    }

    return(
        <>
            <div className='d-block p-5'>
                <div className='w-25 container'>
                    <LetterAvatars handleClick={handleRemovePlayer} players={players}></LetterAvatars>
                </div>

                <div className='container mt-5' style={{width: "30%"}}>
                    <div className='d-flex justify-content-between mb-5'>
                        <TextField id="outlined-basic" label="Player" variant="outlined" onChange={handleNameChange}/>
                        <Button variant="contained" color="primary" onClick={handleAddClick}>Add</Button>
                        <Button variant="contained" color="error" onClick={handleResetClick}>Reset</Button>
                    </div>
                    <div className='d-flex justify-content-between'>
                        <BasicSelect values={[2,3,4,5]} label="Team size" currentValue={size} handleChange={handleSizeChange}></BasicSelect>
                        <BasicSelect values={[2,3,4]} label="Team num" currentValue={number} handleChange={handleNumberChange}></BasicSelect>
                        <Button variant="contained" color="primary" onClick={handlePlayClick}>Play</Button>
                    </div>
                </div>
            
                <div className='d-flex justify-content-md-between mt-5'>

                    {teams.map(team =>{
                        return <div className='text-center'>
                            <h1>Team</h1>
                            <LetterAvatars handleClick={handleRemovePlayer} players={team}></LetterAvatars>
                        </div> 
                        
                    })}
                </div>

                <Snackbar open={open} autoHideDuration={6000} onClose={handleSnackClose}>
                    <Alert onClose={handleSnackClose} severity="error" sx={{width: '100%'}}>
                        {error}
                    </Alert>
                </Snackbar>
            </div>
        </>
    )
} export default AramTeam