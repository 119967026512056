import React, { useState, useEffect, useCallback, useRef} from 'react';

const Fluid = ({ heigth, width }) => {
    const [fluid, setFluid] = useState([]);
    const [start, setStart] = useState(0);
    const currentFluid = useRef(fluid);

    useEffect(() => {
        currentFluid.current = fluid;
    }, [fluid]);

    const generateFluid = useCallback(() => {
        const newFluid = Array.from({ length: heigth }, () =>
            Array.from({ length: width }, () => 0)
        );

        heatZone(newFluid,25,25,38,38)

        setFluid(newFluid)
        return newFluid
    }, [heigth, width])

    const heatZone = (currentFluid, sizeX, sizeY, x, y) => {
        for (let i = y; i <= sizeY+y; i++) {
            for (let j = x; j <= sizeX+x; j++) {
                
                currentFluid[i][j] = 4080;
            }
        }
    };
    

    const updateFluid = useCallback(() => {
        let dt = 1
        let dx = 1.0
        let d = 0.1

        var newFluid = Array.from({ length: heigth }, () =>
            Array.from({ length: width }, () => 0)
        );

        for (let i = 1; i < heigth - 1; i++) {
            for (let j = 1; j < width - 1; j++) {
                let laplacian = (currentFluid.current[i + 1][j] + currentFluid.current[i - 1][j] + currentFluid.current[i][j + 1] + currentFluid.current[i][j - 1] - 4*currentFluid.current[i][j]) / (dx * dx);
                newFluid[i][j] = Math.floor(currentFluid.current[i][j] + d * dt * laplacian)
            }
        }

        heatZone(newFluid,25,25,38,38)

        setFluid(newFluid)
    }, [heigth, width]);

    const setColor = useCallback((value) => {
        let rgb = ""

        if(value>=1010){
            rgb = "rgba(255,0, 0,1)"
        }

        else if (value >= 765) {
            rgb = "rgba(" + 255 + "," + (84+ (255-84 - (value - 765))) + ', 0,1)'
        }
        else if (value >= 510 && value < 765) {
            rgb = "rgba(" + (255 + ((value - 510+84)-255)) + "," + 255 + ",0,1)"
        }
        else if (value >= 255 && value < 510) {
            let calc = (84+ (255-84 - (value-255)))
            rgb = "rgba(" + "0," + 255 + "," + calc + ",1)"
        }
        else if(value < 255){
            rgb = "rgba(" + "0," + (255+(value-255+84)) + "," + 255 + ",1)"
            //console.log(rgb)
        }

        //console.log(rgb)
        return {
            borderRadius:"30%",
            backgroundColor: rgb,
        };
    }, []);

    const handleClick = () =>{
        setStart(!start)
    }

    useEffect(() => {
        generateFluid();
        if(start){
            const timer = setInterval(() => {
                updateFluid();
            }, 1);
        
            return () => clearInterval(timer);
        }
    }, [generateFluid,start]);
    

    

    return (
        <div>
            <div className='d-flex column m-auto' style={{width:"fit-content"}}>
                {fluid.map((row, rowIndex) => (
                    <div key={rowIndex}>
                        {row.map((cell, colIndex) => (
                            <div className='pixel' style={setColor(cell)} key={colIndex}></div>
                        ))}
                    </div>
                ))}
            </div>
            
            <button className='position-relative start-50 translate-middle-x mt-5 border border-light rounded' style={
                {
                    backgroundColor:"transparent", 
                    color:"white", 
                    width: "100px", 
                    height:"50px", 
                    fontSize:"20px"
                }
            } onClick={handleClick}> {start ? "Stop":"Start"} </button>
        </div>
    )}; export default Fluid